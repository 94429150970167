import Alpine from "alpinejs";
import morph from '@alpinejs/morph'
import htmx from "htmx.org";
import { asyncScripts } from "./asyncScripts";

import { formToObject, getStock, debounce } from "./helpers";

function init() {
  window.Alpine = Alpine;

  Alpine.magic("alta", () => {
    return {
      getStock,
      formToObject,
    };
  });

  Alpine.plugin(morph);
  Alpine.start();

  window.asyncScripts = asyncScripts;
  window.htmx = htmx;
}
init();

// htmx skip sending csrf token
document.addEventListener("htmx:configRequest", function (event) {
  if (event.detail.verb == "get") {
    delete event.detail.parameters["csrfmiddlewaretoken"];
  }
});

// add loading class to target element
document.addEventListener("htmx:beforeRequest", function (event) {
  const hxTarget = event.detail.elt.getAttribute("hx-target");
  if (!hxTarget) return;
  var target = document.querySelector(hxTarget);
  target.classList.add("loading");
});

// add scroll class when page is scrolled
let scrolled = false;
const tagScrolled = () => {
  scrolled = true;
  document.body.classList.add("scrolled");
};
const removeScrolled = () => {
  scrolled = false;
  document.body.classList.remove("scrolled");
};
document.addEventListener(
  "scroll",
  debounce(() => {
    const scroll = window.scrollY;
    if (scrolled && scroll < 100) {
      removeScrolled();
    }
    if (!scrolled && scroll > 150) {
      tagScrolled();
    }
  })
);

// File reload

if (process.env.NODE_ENV !== "production") {
  htmx.logAll();
  try {
    const ws = new WebSocket("ws://localhost:1235");
    const el = document.createElement("div");
    el.style =
      "position:fixed;bottom:0;right:0;background-color:#f00000aa;color:#fff;padding:2px 5px;z-index:9999;";
    el.innerHTML = "🤖";
    document.body.appendChild(el);
    ws.addEventListener("error", (err) => {
      document.body.removeChild(el);
      console.log("Error", err);
    });
    ws.addEventListener("close", () => {
      if (document.body.contains(el)) {
        document.body.removeChild(el);
      }
      console.log("Close");
    });
    let t;
    ws.addEventListener("message", function (event) {
      if (event.data === "reload") {
        if (t) clearTimeout(t);
        t = setTimeout(() => {
          location.reload();
        }, 400);
      }
    });
  } catch (error) {
    console.info("WS not connected");
  }
}
